import React from 'react';
import DefaultAvatar from '../../assets/defaultAvatar.png';
import Button from '../ui-kit/Button';

function AdvertisingBlock({ author, title, description, price, category, subcategory, paymentMethod, showButton }) {
  return (
    <div className='AdvertisingBlock'>
      <div className="header">
        <div className="headerLeft">
          <div className="avatar">
            <img
              src={author.avatar ? `https://api.loonw-space.com:5000${author.avatar}` : DefaultAvatar}
              alt="Avatar"
            />
          </div>
          <div className="name">{author.nickname || 'Анонимный пользователь'}</div>
        </div>
        <div className="headerRight">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      </div>

      <div className="title">{title}</div>
      <div className="description">{description}</div>
      <div className="price"><span>Цена:</span> {price} {paymentMethod}</div>

      {showButton ? (<Button text='Связаться с исполнителем' />) : null}
    </div>
  );
}

export default AdvertisingBlock;
