import React from 'react'
import DefaultAvatar from '../../assets/defaultAvatar.png'
import { IoIosStar } from 'react-icons/io'

function ReviewsBlock() {
  return (
    <div className='ReviewsBlock'>
        <div className="header">
            <div className="author">
                <div className="avatar">
                    <img src={DefaultAvatar} alt="" />
                </div>

                <div className="name">
                    DOR RITO
                </div>
            </div>

            <div className="stars">
                <div className="icon">
                    <IoIosStar />
                </div>
                <div className="icon">
                    <IoIosStar />
                </div>
                <div className="icon">
                    <IoIosStar />
                </div>
                <div className="icon">
                    <IoIosStar />
                </div>
                <div className="icon">
                    <IoIosStar />
                </div>
            </div>
        </div>

        <div className="text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro similique hic, laudantium eos fugit repudiandae nam et cupiditate in libero doloribus incidunt accusamus nemo voluptates ipsa saepe dicta labore voluptatem.
        </div>
    </div>
  )
}

export default ReviewsBlock