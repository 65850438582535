import React from 'react'
import ReviewsBlock from './ReviewsBlock'

function ReviewsProfile() {
  return (
    <div className='ReviewsProfile'>
        <div className="title">
            Отзывы(2):
        </div>

        <ReviewsBlock />
        <ReviewsBlock />
    </div>
  )
}

export default ReviewsProfile