import React from 'react';

function Button({
    text = "",
    icon,
    onPress = () => {},
    to = "",
    style = "",
    ...props
}) {
  return (
    <a className={`blueButton ` + style} href={to} onClick={onPress} {...props}>{text}</a>
  )
}

export default Button;
