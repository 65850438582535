import React from 'react'
import AddResponseWrapper from '../components/AddResponse/AddResponseWrapper'
import { useParams } from 'react-router-dom';

function AddResponse() {
  const { orderId } = useParams();

  return (
    <div className='AddResponse'>
        <AddResponseWrapper orderId={orderId} />
    </div>
  )
}

export default AddResponse