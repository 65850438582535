import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useChatStore from '../store/chatStore';

function Chat({ userId }) {
  const { chatId } = useParams();
  const { currentChat, messages, sendMessage, setCurrentChat, initWebSocket } = useChatStore();
  const [inputText, setInputText] = useState('');

  useEffect(() => {
    initWebSocket(userId);
    setCurrentChat(chatId);
  }, [chatId, setCurrentChat, initWebSocket, userId]);

  const handleSendMessage = () => {
    sendMessage(chatId, userId, inputText);
    setInputText('');
  };

  return (
    <div className="Chat">
      <div className="messages">
        {messages.map((msg, index) => (
          <div key={index} className={msg.senderId === userId ? 'sent' : 'received'}>
            {msg.text}
          </div>
        ))}
      </div>
      <input
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
      />
      <button onClick={handleSendMessage}>Отправить</button>
    </div>
  );
}

export default Chat;
