import React from 'react'
import MenuUser from '../components/Menu/MenuUser'
import FriendButton from '../components/Menu/FriendButton'
import MenuButtons from '../components/Menu/MenuButtons'

function Menu() {
  return (
    <div className='Menu'>
      <MenuUser />

      <FriendButton />

      <MenuButtons />
    </div>
  )
}

export default Menu