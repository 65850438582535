import axios from 'axios';

const BASE_URL = 'https://api.loonw-space.com:5000/api';

export const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      console.error('Ошибка ответа от сервера:', error.response);
    } else if (error.request) {
      console.error('Запрос был сделан, но ответ не получен:', error.request);
    } else {
      console.error('Ошибка при настройке запроса:', error.message);
    }
    return Promise.reject(error);
  }
);

export default api;