import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { api } from '../api/api';
import { showNotification } from '../ToastNotification';

const useAdvertisingStore = create(
  devtools((set) => ({
    advertisements: [],
    loading: false,

    createAdvertisement: async (adData, files) => {
      set({ loading: true });
      try {
        const formData = new FormData();
        Object.entries(adData).forEach(([key, value]) => formData.append(key, value));
        files.forEach((file) => formData.append('files', file.file));

        const response = await api.post('/advertisements/', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        set((state) => ({ advertisements: [...state.advertisements, response.data], loading: false }));
        showNotification('Объявление успешно создано!', 'success');
      } catch (error) {
        console.error('Ошибка при создании объявления:', error);
        showNotification('Ошибка при создании объявления', 'error');
        set({ loading: false });
      }
    },

    fetchAdvertisements: async () => {
      set({ loading: true });
      try {
        const response = await api.get('/advertisements');
        set({ advertisements: response.data, loading: false });
      } catch (error) {
        console.error('Ошибка при загрузке объявлений:', error);
        showNotification('Ошибка при загрузке объявлений', 'error');
        set({ loading: false });
      }
    },

    fetchFilteredAdvertisements: async (categoryId, subcategoryId) => {
      set({ loading: true });
      try {
        console.log('Фильтрация:', { categoryId, subcategoryId });
        const response = await api.get(`/advertisements/${categoryId}/${subcategoryId}`);
        console.log('Ответ сервера:', response.data);
        set({ advertisements: response.data, loading: false });
      } catch (error) {
        console.error('Ошибка при загрузке объявлений:', error);
        showNotification('Ошибка при загрузке объявлений', 'error');
        set({ loading: false });
      }
    },

    fetchUserAdvertising: async (userId) => {
      set({ loading: true });
      try {
        const response = await api.get(`/advertisements/${userId}`);
        console.log('Пользовательские объявления:', response.data);
        set({ advertisements: response.data, loading: false });
      } catch (error) {
        console.error('Ошибка при загрузке объявлений пользователя:', error);
        showNotification('Ошибка при загрузке объявлений пользователя', 'error');
        set({ loading: false });
      }
    }

  }))
);

export default useAdvertisingStore;
