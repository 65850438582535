import React, { useEffect } from 'react';
import HomeOne from '../components/Home/HomeOne';
import CategoryBlock from '../components/Home/CategoryBlock';
import OrderBlock from '../components/Orders/OrderBlock';
import useCategoryStore from '../store/categoriesStore';
import useUserStore from '../store/userStore';
import useOrderStore from '../store/orderStore';

function Home() {
  const categories = useCategoryStore((state) => state.categories);
  const fetchCategories = useCategoryStore((state) => state.fetchCategories);
  const { user, fetchUserInfo, loading: userLoading } = useUserStore();
  const { allOrders, fetchAllOrders, loading: ordersLoading } = useOrderStore();

  useEffect(() => {
    fetchCategories();
    fetchUserInfo();
    fetchAllOrders();
  }, [fetchCategories, fetchUserInfo, fetchAllOrders]);


  return (
    <div className="Home">
      <HomeOne />

      <div className="home__container">
        {user?.role === 'Заказчик' ? (
          <div className="customerSection">
            {categories.map((category) => (
              <CategoryBlock
                key={category._id}
                id={category._id}
                title={category.name}
                text={category.description}
                cards={category.subcategories.slice(0, 3).map((subcategory) => ({
                  id: subcategory._id,
                  title: subcategory.name,
                  text: `${subcategory.offers || 0} предложений`,
                  image: subcategory.imageUrl,
                }))}
              />
            ))}
          </div>
        ) : user?.role === 'Исполнитель' ? (
          <div className="executorSection">
            {allOrders.length > 0 ? (
              allOrders.map((order) => (
                <OrderBlock
                  key={order._id}
                  author={{
                    name: order.customerId.nickname,
                    avatar: order.customerId.avatar,
                  }}
                  title={order.title}
                  desc={order.description}
                  price={order.acceptablePrice}
                  responses={`Откликов: ${order.responses?.length || 0}`}
                  files={order.files}
                  textButton="Откликнуться"
                  hrefButton={`/addResponse/${order._id}`}
                />
              ))
            ) : (
              <p>Заказы отсутствуют</p>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Home;
