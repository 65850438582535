import React, { useEffect } from 'react';
import OrderBlock from '../components/Orders/OrderBlock';
import useOrderStore from '../store/orderStore';

function Orders() {
  const { orders, fetchUserOrders, loading } = useOrderStore();

  useEffect(() => {
    console.log(orders)
    fetchUserOrders();
  }, [fetchUserOrders]);

  return (
    <div className="Orders">
      <div className="Orders__container">
        <div className="tab__grids">
          <div className="tab__block active">На бирже</div>
          <div className="tab__block">В работе</div>
          <div className="tab__block">Завершено</div>
          <div className="tab__block">Отменено</div>
        </div>

        {loading ? (
          <div>Загрузка...</div>
        ) : (
          orders.map((order) => (
            <OrderBlock
                key={order._id}
                author={{
                    name: order.customerId.nickname,
                    avatar: order.customerId.avatar
                }}
                title={order.title}
                desc={order.description}
                price={order.acceptablePrice}
                responses={`Откликов: ${order.responses.length}`}
                files={order.files}
                textButton="Смотреть отклики"
                hrefButton={`/responses/${order._id}`}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default Orders;
