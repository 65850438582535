import React from 'react'
import BalanceHeader from '../components/Balance/BalanceHeader'

function Balance() {
    return (
        <div className='Balance'>
            <div className="Balance__container">
                <div className="titlePage">
                    Баланс
                </div>

                <BalanceHeader />

                <div className="text">
                    История транзакций пуста
                </div>
            </div>
        </div>
    )
}

export default Balance