import React from 'react'

function Textarea(props) {
  return (
    <>  
        <div className="label">{props.label}</div>
        <textarea
          className='textarea'
          placeholder={props.text}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        ></textarea>
    </>
  )
}

export default Textarea
