import React from 'react';

function Select(props) {
  return (
    <>  
        <div className="label">{props.label}</div>
        <select
          className="select"
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        >
          {props.items.map((item, index) => (
            <option className='option' key={index} value={item.value}>{item.title}</option>
          ))}
        </select>
    </>
  );
}

export default Select;
