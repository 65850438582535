import React from 'react'
import { BsPersonAdd } from "react-icons/bs";

function FriendButton() {
  return (
    <div className='FriendButton'>
        <div className="FriendButton__container">
            <a href="/referrals">
                <div className="icon">
                    <BsPersonAdd />
                </div>

                <div className="text">
                    Пригласить друзей
                </div>
            </a>
        </div>
    </div>
  )
}

export default FriendButton