import React from 'react'
import RefferalsWrapper from '../components/Regerrals/RefferalsWrapper'

function Referrals() {
  return (
    <div className='Referrals'>
      <RefferalsWrapper />
    </div>
  )
}

export default Referrals