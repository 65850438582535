import React, { useEffect } from 'react';
import AdvertisingBlock from '../components/Advertising/AdvertisingBlock';
import { useParams } from 'react-router-dom';
import useAdvertisingStore from '../store/useAdvertisingStore';
import useCategoryStore from '../store/categoriesStore';

function Advertising() {
  const { advertisements, fetchFilteredAdvertisements, loading } = useAdvertisingStore();
  const { categories, fetchCategories } = useCategoryStore();
  const { subCategoryId, categoryId } = useParams();

  // Получаем название категории и подкатегории
  const category = categories.find((cat) => cat._id === categoryId);
  const subcategory = category?.subcategories?.find((sub) => sub._id === subCategoryId);

  useEffect(() => {
    // Загружаем категории, если они еще не загружены
    if (categories.length === 0) {
      fetchCategories();
    }
    // Загружаем объявления
    fetchFilteredAdvertisements(categoryId, subCategoryId);
  }, [fetchFilteredAdvertisements, fetchCategories, categories, categoryId, subCategoryId]);

  return (
    <div className='Advertising'>
      <div className="Advertising__container">
        <div className="titlePage">Предложения исполнителей</div>
        <div className="descPage">
          {subcategory
            ? `В подкатегории: ${subcategory.name}`
            : category
              ? `В категории: ${category.name}`
              : 'Все категории'}
        </div>

        {loading ? (
          <div>Загрузка...</div>
        ) : (
          advertisements.map((ad) => (
            <AdvertisingBlock
              key={ad._id}
              author={ad.authorId}
              title={ad.title}
              description={ad.description}
              paymentMethod={ad.paymentMethod}
              price={ad.price}
              showButton={true}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default Advertising;
