import React, { useEffect } from 'react';
import WebApp from '@twa-dev/sdk';
import useAdvertisingStore from '../store/useAdvertisingStore';
import AdvertisingBlock from '../components/Advertising/AdvertisingBlock';

function MyAdvertising() {
    const { advertisements, fetchUserAdvertising, loading } = useAdvertisingStore();
    const user = WebApp.initDataUnsafe?.user;

    useEffect(() => {
        if (user?.id) {
            fetchUserAdvertising(user.id);
        }
    }, [user?.id, fetchUserAdvertising]);

    return (
        <div className='MyAdvertising'>
            <div className="MyAdvertising__container">
                <div className="titlePage">Мои объявления на бирже</div>

                {loading ? (
                    <div>Загрузка...</div>
                ) : advertisements.length > 0 ? (
                    advertisements.map((ad) => (
                        <AdvertisingBlock
                            key={ad._id}
                            author={ad.authorId}
                            title={ad.title}
                            description={ad.description}
                            price={ad.price}
                            paymentMethod={ad.paymentMethod}
                            category={ad.categoryId.name}
                            subcategory={ad.subcategoryId.name}
                            showButton={false}
                        />
                    ))
                ) : (
                    <div>У вас пока нет объявлений</div>
                )}
            </div>
        </div>
    );
}

export default MyAdvertising;
