import React from 'react';
import DefaultAvatar from '../../assets/defaultAvatar.png';
import Button from '../ui-kit/Button';
import { BsPaperclip } from 'react-icons/bs';
import { SiTon } from "react-icons/si";

function OrderBlock({ author, title, desc, price, responses, files, hrefButton, textButton, showButton = [] }) {
  return (
    <div className="OrderBlock">
      <div className="header">
        <div className="headerLeft">
          <div className="avatar">
          <img src={`https://api.loonw-space.com:5000${author.avatar}` && DefaultAvatar} alt="Avatar" />
          </div>
          <div className="name">{author.name}</div>
        </div>
        <div className="headerRight">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      </div>

      <div className="title">{title}</div>
      <div className="desc">{desc}</div>

      <div className="files">
        {files.map((file, index) => (
          <a key={index} href={file} target="_blank" rel="noopener noreferrer">
            <div className="icon">
                <BsPaperclip className="fileIcon" />
            </div>

            <div className="file">
                {file.split('/').pop()}
            </div>
          </a>
        ))}
      </div>

      <div className="infoOrder">
        <div className="price">Допустимая цена: {price} <div className="icon"><SiTon/></div> </div>
        <div className="responses">{responses}</div>
      </div>

      {showButton && (<Button to={hrefButton} text={textButton} />)}
    </div>
  );
}

export default OrderBlock;
