import React from 'react'

function Input(props) {
  return (
    <>  
        <div className="label">{props.label}</div>
        <input
          className='input'
          placeholder={props.text}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
    </>
  )
}

export default Input
