import React, { useEffect } from 'react';
import { SiTon } from "react-icons/si";
import { AiFillDollarCircle } from "react-icons/ai";
import { BsCommand } from "react-icons/bs";
import BalanceCurrency from './BalanceCurrency';
import Button from '../ui-kit/Button';
import useUserStore from '../../store/userStore';

function BalanceHeader() {
    const { user, fetchUserInfo, loading } = useUserStore();

    useEffect(() => {
        if (!user) {
            fetchUserInfo();
        }
    }, [user, fetchUserInfo]);

    if (loading || !user) {
        return <div>Загрузка...</div>;
    }

    const isPerformer = user.role === 'Исполнитель';

    return (
        <div className='BalanceHeader'>
            <BalanceCurrency
                icon={<SiTon />}
                color={'#3478F6'}
                paddingTop={10.5}
                currentBalance={user.balance}
                currentCurency={'TON'}
            />

            <BalanceCurrency
                icon={<AiFillDollarCircle />}
                color={'#5FBF86'}
                paddingTop={10.5}
                currentBalance={user.balanceUSDT}
                currentCurency={'USDT'}
            />

            <BalanceCurrency
                icon={<BsCommand />}
                color={'#6328f6'}
                paddingTop={10.5}
                currentBalance={user.balanceLSpace}
                currentCurency={'$LS'}
            />

            <Button text={isPerformer ? "Вывод средств" : "Пополнить"} />
        </div>
    );
}

export default BalanceHeader;
