import { create } from 'zustand';
import { api } from '../api/api';
import { devtools } from 'zustand/middleware';

const useCategoryStore = create(
  devtools((set, get) => ({
    categories: [],
    subcategories: {},
    isLoading: false,

    fetchCategories: async () => {
      if (get().isLoading) return;

      set({ isLoading: true });
      try {
        const response = await api.get('/categories');
        set({ categories: response.data });
      } catch (error) {
        console.error('Ошибка при загрузке категорий:', error);
      } finally {
        set({ isLoading: false });
      }
    },

    fetchSubcategoriesByCategoryId: async (categoryId) => {
      try {
        const response = await api.get(`/categories/${categoryId}/subcategories`);
        set((state) => ({
          subcategories: { ...state.subcategories, [categoryId]: response.data },
        }));
      } catch (error) {
        console.error(`Ошибка при загрузке подкатегорий для категории ${categoryId}:`, error);
      }
    },
  }))
);

export default useCategoryStore;
