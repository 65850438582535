import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useChatStore from '../../store/chatStore';

function ChatList() {
  const { chats, fetchChats, setCurrentChat } = useChatStore();
  const navigate = useNavigate();

  useEffect(() => {
    fetchChats();
  }, [fetchChats]);

  const handleChatClick = (chatId) => {
    setCurrentChat(chatId);
    navigate(`/chat/${chatId}`);
  };

  return (
    <div className="ChatList">
      {chats.map((chat) => (
        <div key={chat._id} onClick={() => handleChatClick(chat._id)} className="chat">
          <h3>{chat.participants.find((p) => p.telegramId !== chat._id)?.nickname}</h3>
        </div>
      ))}
    </div>
  );
}

export default ChatList;
