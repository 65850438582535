import React from 'react'

function MenuButton(props) {
  return (
    <div className='MenuButton'>
        <a href={props.to}>
            <div className="icon">
                {props.icon}
            </div>

            <div className="text">
                {props.text}
            </div>
        </a>
    </div>
  )
}

export default MenuButton