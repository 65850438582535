import React from 'react'
import { IoIosStar } from "react-icons/io";

function ReviewProfile() {
  return (
    <div className='ReviewProfile'>
        <div className="left">
            Сделано заказов: <span>0</span>
        </div>

        <div className="right">
            <div className="icon">
                <IoIosStar />
            </div>
            <div className="icon">
                <IoIosStar />
            </div>
            <div className="icon">
                <IoIosStar />
            </div>
            <div className="icon">
                <IoIosStar />
            </div>
            <div className="icon">
                <IoIosStar />
            </div>
        </div>
    </div>
  )
}

export default ReviewProfile