import React from 'react'
import AddOrderWrapper from '../components/AddOrder/AddOrderWrapper'

function AddOrder() {
  return (
    <div className='AddProject'>
        <div className="AddProject__container">
            <div className="titlePage">
                Добавить проект
            </div>

            <AddOrderWrapper />
        </div>
    </div>
  )
}

export default AddOrder