import React from 'react'
import ChatList from '../components/Chats/ChatList'

function Chats() {
  return (
    <div className='Chats'>
        <ChatList />
    </div>
  )
}

export default Chats