import React from 'react';

function DescProfile({ description }) {
  return (
    <div className="DescProfile">
      <div className="title">Описание профиля:</div>
      <div className="text">
        {description || 'Описание отсутствует.'}
      </div>
    </div>
  );
}

export default DescProfile;
