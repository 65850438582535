import React, { useEffect } from 'react';
import { BsPencil } from "react-icons/bs";
import DefaultAvatar from '../../assets/defaultAvatar.png';
import useUserStore from '../../store/userStore';

function MenuUser() {
  const { user, fetchUserInfo, loading } = useUserStore();

  useEffect(() => {
    fetchUserInfo();
  }, [fetchUserInfo]);

  if (loading) {
    return <div className="MenuUser__loading">Загрузка...</div>;
  }

  if (!user) {
    return <div className="MenuUser__error">Пользователь не найден</div>;
  }

  return (
    <div className="MenuUser">
      <div className="MenuUser__container">
        <div className="avatar">
          <img 
            src={user.avatar ? `https://api.loonw-space.com:5000${user.avatar}` : DefaultAvatar} 
            alt="User Avatar" 
          />
        </div>

        <div className="info">
          <div className="fullName">
            <div className="name">{user.nickname || 'Без имени'}</div>
          </div>

          <div className="specialnost">
            {user.specialnost || 'Специальность не указана'}
          </div>
        </div>

        <a href="/editprofile" className="editButton">
          <BsPencil />
        </a>
      </div>
    </div>
  );
}

export default MenuUser;
