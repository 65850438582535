import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useCategoryStore from '../store/categoriesStore';
import { BsArrowRight } from 'react-icons/bs';

function Category() {
  const { id } = useParams();
  const categories = useCategoryStore((state) => state.categories);
  const fetchCategories = useCategoryStore((state) => state.fetchCategories);

  const currentCategory = categories.find((category) => category._id === id);
  const subcategories = currentCategory?.subcategories || [];

  useEffect(() => {
    if (categories.length === 0) {
      fetchCategories();
    }
  }, [categories, fetchCategories]);

  return (
    <div className="Category">
      <div className="Category__container">
        <div className="titlePage">
          {currentCategory ? currentCategory.name : 'Категория не найдена'}
        </div>

        <div className="categories">
          {subcategories.length > 0 ? (
            subcategories.map((subcategory, index) => (
              <a key={index} href={`/advertising/${id}/${subcategory._id}`} className="categoriesBlock">
                <div className="title">{subcategory.name}</div>
                <div className="icon">
                  <BsArrowRight />
                </div>
              </a>
            ))
          ) : (
            <div className="categoriesBlock">Нет подкатегорий</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Category;
