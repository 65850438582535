import React from 'react';
import { useNavigate } from 'react-router-dom';

function CategoryCard(props) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/advertising/${props.parentCategoryId}/${props.subcategoryId}`);
  };

  return (
    <div className='CategoryCard' onClick={handleClick}>
      <div className="image">
        <img src={props.image} alt="" />
      </div>
      <div className="title">
        {props.title}
      </div>
      <div className="text">
        {props.text}
      </div>
    </div>
  );
}

export default React.memo(CategoryCard);
