import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import BottomNavigation from './components/BottomNavigation';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Menu from './pages/Menu';
import AddOrder from './pages/AddOrder';
import Orders from './pages/Orders';
import Referrals from './pages/Referrals';
import EditProfile from './pages/EditProfile';
import Profile from './pages/Profile';
import Category from './pages/Category';
import AddAdvertising from './pages/AddAdvertising';
import Advertising from './pages/Advertising';
import MyAdvertising from './pages/MyAdvertising';
import Balance from './pages/Balance';
import AddResponse from './pages/AddResponse';
import Chat from './pages/Chat'; // Новая страница чата
import Chats from './pages/Chats';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/category/:id" element={<Category />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/addOrder" element={<AddOrder />} />
        <Route path="/addAdvertising" element={<AddAdvertising />} />
        <Route path="/myAdvertising" element={<MyAdvertising />} />
        <Route path="/advertising/:categoryId/:subCategoryId" element={<Advertising />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/referrals" element={<Referrals />} />
        <Route path="/editProfile" element={<EditProfile />} />
        <Route path="/profile/:telegramId" element={<Profile />} />
        <Route path="/balance" element={<Balance />} />
        <Route path="/addResponse/:orderId" element={<AddResponse />} />
        <Route path="/chats" element={<Chats />} />
        <Route path="/chat/:chatId" element={<Chat />} />
      </Routes>

      <BottomNavigation />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </BrowserRouter>
  );
}

export default React.memo(App);
