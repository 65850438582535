import React, { useState, useEffect } from 'react';
import { BsBoxSeam } from "react-icons/bs";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from '../ui-kit/Button';

function RefferalsWrapper() {
  const [copied, setCopied] = useState(false);

  return (
    <div className='RefferalsWrapper'>
      <div className="RefferalsWrapper__container">
        <div className="titlePage">
          Реферальная система
        </div>

        <div className="infoBlock">
          <div className="left">
            <BsBoxSeam />
          </div>

          <div className="right">
            <div className="title">
              Получите:
            </div>

            <div className="text">
              150 $LS за приведенного друга
            </div>
          </div>
        </div>

        <CopyToClipboard text={"referal"} onCopy={() => setCopied(true)}>
          <Button text={copied ? "Скопировано!" : "Копировать ссылку"} />
        </CopyToClipboard>

        
      </div>
    </div>
  );
}

export default RefferalsWrapper;
