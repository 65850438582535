import React from 'react';
import DefaultAvatar from '../../assets/defaultAvatar.png';

function ProfileHeader({ user }) {
  return (
    <div className="MenuUser">
      <div className="MenuUser__container">
        <div className="avatar">
            <img src={`https://api.loonw-space.com:5000${user.avatar}` && DefaultAvatar} alt="Avatar" />
        </div>

        <div className="info">
          <div className="fullName">
            <div className="name">{user.nickname || 'Без имени'}</div>
          </div>

          <div className="specialnost">
            {user.specialnost || 'Специальность не указана'}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileHeader;
