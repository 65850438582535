import React from 'react'

function BalanceCurrency(props) {
    return (
        <div className="currency">
            <div className="left">
                <div className="logo">
                    <div className="icon" style={{ color: props.color, paddingTop: props.paddingTop }}>
                        {props.icon}
                    </div>
                </div>

                <div className="title">
                    Ваш баланс:
                </div>
            </div>

            <div className="current" style={{ color: props.color }}>
                {props.currentBalance} {props.currentCurency}
            </div>
        </div>
    )
}

export default BalanceCurrency